import React, { useState } from "react";
import soundFile from "./assets/diamonds.mp3";

const MusicButton = () => {
  const [audio] = useState(new Audio(soundFile));
  const [isPlaying, setIsPlaying] = useState(false);

  const handleToggleSound = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying); // Toggle the play/pause state
  };

  // Ensure audio state resets when the sound ends
  audio.onended = () => setIsPlaying(false);

  return (
    <div className="button-container">
      <button className="button" onClick={handleToggleSound}>
        <div className="button__content flex gap-2">
          <p className="button__text text-xl">{isPlaying ? "PAUSE" : "PLAY"}</p>
          <p className="button__text text-3xl">💎</p>
        </div>
      </button>
    </div>
  );
};

export default MusicButton;
