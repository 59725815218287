import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";
import animationData from "./assets/diamond.json";
import coins from "./assets/coins.png";
import ston from "./assets/stonfi.svg";
import tgLogo from "./assets/tg-logo.svg";
import copy from "./assets/copy.svg";
import tonCoin from "./assets/toncoin.png";
import ranVideo from "./assets/ran-video.mp4";
import tweet1 from "./assets/tweet1.png";
import tweet2 from "./assets/tweet2.png";
import tweet3 from "./assets/tweet3.png";
import tweet4 from "./assets/tweet4.png";
import xcom from "./assets/xcom.svg";
import dex from "./assets/dex.svg";
import gecko from "./assets/gecko.png";
import tgBg from "./assets/tg-bg.png"; // Importing background image
import MusicButton from "./MusicButton";
import { Toaster, toast } from "react-hot-toast";

// Import required modules
import Swiper, { Pagination } from "swiper";
import { SwiperClass } from "swiper/react";
import Swipe from "./Swiper";

export const fadeIn = {
  initial: { opacity: 0.15, scale: 1 },
  whileInView: { opacity: 1, scale: 1 },
  viewport: { once: false, amount: 0.3 },
  transition: { duration: 0.5 },
};

function App() {
  const [dots, setDots] = useState(""); // State to control the dots animation
  const contractAddress = "EQB6Ql1JE7Hq0JuropLYschcu-WNt2vMmWF2-8mEMrK68nJn"; // Example contract address
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => {
        if (prev.length === 3) {
          return ""; // Reset to no dots
        } else {
          return prev + "."; // Add one more dot
        }
      });
    }, 500); // Change dots every 500ms (half second)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    toast.success("Contract address copied!", {
      style: {
        padding: "16px",
      },
    });
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="relative flex flex-col items-center justify-start w-full h-[100vh] min-h-screen text-white overflow-x-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center opacity-20 contrast-100 md:hidden"
        style={{ backgroundImage: `url(${tgBg})` }}
      ></div>
      <Toaster position="top-right" reverseOrder={false} />

      {/* Main content with z-10 to ensure it's above the background */}
      <header className="relative w-full flex max-w-screen-lg justify-between z-10 p-6">
        {/* Animated logo */}
        <motion.span
          className="text-4xl"
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 150 }}
        >
          💎
        </motion.span>
        <button className="flex items-center gap-1 px-3.5 py-2 text-md text-[#14A7FD] font-normal bg-white hover:bg-white/90 transition ease-in-out duration-150 rounded-full">
          Telegram <img className="h-7 w-7" src={tgLogo} />
        </button>
      </header>

      {/* Wrapper for Hero Section */}
      <div className="relative w-full h max-w-screen-lg flex flex-col md:flex-row md:my-10 justify-between items-center md:items-start px-6 z-10">
        {/* Text Section */}
        <div className="text-start flex flex-col gap-3 md:w-1/2 mt-10 md:mt-0">
          <h2 className="">The Official, Unofficial Emoji of TON</h2>
          <h1 className="font-bold text-5xl md:text-7xl uppercase">
            The First Emoji Ticker{" "}
          </h1>
          <span className="flex items-center gap-2 font-bold text-5xl md:text-7xl uppercase -translate-y-3">
            on TON <img className="w-12 h-auto" src={tonCoin} />
          </span>
        </div>

        {/* Image & Lottie Section */}
        <div className="flex flex-col items-center text-center md:w-1/2">
          <div className="relative">
            <img
              className="absolute z-0 bottom-8 -rotate-45 -right-5 w-48 md:w-48 h-auto opacity-10 saturate-0 contrast-125"
              src={coins}
            />
            <img
              className="absolute z-0 bottom-16 rotate-15 left-8 w-28 h-auto opacity-10 saturate-0 contrast-125"
              src={tonCoin}
            />
            <motion.div
              animate={{ y: [0, -10, 0] }}
              transition={{
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              className="relative"
            >
              <Lottie
                animationData={animationData}
                loop={true}
                className="absolute bottom-0 w-80 h-80 mx-auto saturate-200 blur-2xl"
              />
              <Lottie
                animationData={animationData}
                loop={true}
                className="w-80 h-80 mx-auto z-10 saturate-200"
              />
            </motion.div>
          </div>
        </div>
      </div>

      {/* buy and copy */}
      <div className="relative flex flex-col items-center w-full px-6 mb-4 z-10">
        {/* Buy Button with hover scale animation */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          className="flex w-full justify-center md:w-fit items-center gap-3 px-12 py-3.5 border-2 border-blue-300 bg-blue-500 hover:bg-blue-400 transition ease-in-out duration-150 rounded-full font-bold"
        >
          Buy on Ston.fi <img className="h-5 w-5" src={ston} />
        </motion.button>

        {/* Copy Contract Button with hover rotation animation */}
        <motion.button
          className="flex items-center gap-2 px-4 py-2 mt-4 text-md font-medium text-white hover:text-white/90 hover:border-white/90 transition-all ease-in-out duration-150"
          onClick={handleCopy}
        >
          <span className="border-b border-white rounded-sm">
            Copy Contract
          </span>
          <div className="relative">
            <motion.img
              src={copy}
              className="transition-transform ease-in-out duration-150 relative hover:translate-x-2"
              alt="copy icon"
            />
          </div>
        </motion.button>
      </div>

      {/* New Section with White Background and Rounded Top Corners */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="relative w-full flex flex-col items-center bg-white rounded-t-[40px] p-0 mt-2 max-w-screen-lg z-10"
      >
        <div className="w-24 h-1 bg-gray-300 rounded-full mt-4"></div>
        <div className=" mx-auto w-full text-black mt-10">
          <div className="mb-10 w-full flex justify-center">
            <MusicButton />
          </div>
          <div className="flex flex-col w-full justify-center items-center gap-2 mx-auto">
            <h2 className="text-2xl font-bold w-full px-6">EmojiFi 💎</h2>
            <div className="relative w-full flex flex-col items-center bg-white rounded-t-[40px] px-6 z-10 overflow-x-visible max-w-screen-lg">
              <motion.div
                className="w-full border-gray-100 overflow-hidden mt-2 md:m-0"
                {...fadeIn}
              >
                <video
                  ref={videoRef}
                  loop
                  className="rounded-2xl w-full"
                  onClick={togglePlay}
                  src={ranVideo}
                  controls={false}
                ></video>
                {!isPlaying && (
                  <button
                    onClick={togglePlay}
                    className="absolute inset-0 flex items-center justify-center rounded-2xl"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-16 w-16 text-white"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  </button>
                )}
              </motion.div>
            </div>
            <div className="flex flex-col md:flex-wrap w-full max-w-screen-lg justify-center gap-3 mx-auto">
              <motion.p
                className="text-gray-400 font-sm text-center md:w-full mx-2 mb-3 mt-1"
                {...fadeIn}
              >
                Listen to what Ran has to say about.. EmojiFi.
              </motion.p>

              {/* Desktop View */}
              <div className="hidden md:flex flex-wrap justify-center gap-3">
                <motion.div
                  className="rounded-2xl border-2 shadow-lg border-gray-100 overflow-hidden md:w-[40%]"
                  {...fadeIn}
                >
                  <img className="w-full h-full object-cover" src={tweet4} />
                </motion.div>
                <motion.div
                  className="rounded-2xl border-2 shadow-lg border-gray-100 overflow-hidden md:w-[40%]"
                  {...fadeIn}
                >
                  <img className="w-full h-full object-cover" src={tweet1} />
                </motion.div>
                <motion.div
                  className="rounded-2xl border-2 shadow-lg border-gray-100 overflow-hidden md:w-[40%]"
                  {...fadeIn}
                >
                  <img className="w-full h-full object-cover" src={tweet2} />
                </motion.div>
                <motion.div
                  className="rounded-2xl border-2 shadow-lg border-gray-100 overflow-hidden md:w-[40%]"
                  {...fadeIn}
                >
                  <img className="w-full h-full object-cover" src={tweet3} />
                </motion.div>
              </div>

              {/* Mobile View */}
              <div className="block md:hidden w-full mt-4 overflow-visible">
                <Swipe />
              </div>

              <motion.p
                className="text-gray-400 font-sm text-center md:w-full mx-2 mt-1 mb-3"
                {...fadeIn}
              >
                The 💎 is everywhere.
              </motion.p>
            </div>
          </div>

          {/* Placeholder Section for roadmap
          <div className="mt-24">
            <h2 className="text-2xl font-bold mb-6">Roadmap</h2>
            roadmap here
          </div> */}

          <div className="w-full md:justify-center md:flex flex-col">
            <p className="text-md text-gray-500 uppercase mt-16 px-6 md:text-center">
              Join our community
            </p>
            <h2 className="text-2xl font-bold mb-4 px-6  md:text-center">
              Socials & More
            </h2>
            {/* Combined Social and DexScreener/GeckoTerminal Links */}
            <div className="flex px-6 flex-wrap md:flex-row md:justify-center items-start gap-2 mt-6">
              {/* Social Links */}
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-[60px] items-center gap-2 bg-black text-white px-8 py-4 rounded-full hover:bg-blue-600 transition"
              >
                Twitter <img src={xcom} />
              </a>
              <a
                href="https://t.me"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-[60px] items-center gap-2 bg-[#14A7FD] text-white px-8 py-4 rounded-full hover:bg-blue-600 transition"
              >
                Telegram <img src={tgLogo} />
              </a>

              {/* DexScreener and GeckoTerminal Links */}
              <a
                href="https://geckoterminal.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-[60px] items-center gap-2 bg-purple-800 text-white px-8 py-4 rounded-full hover:bg-gray-300 transition"
              >
                GeckoTerminal <img className="h-8" src={gecko} />
              </a>
              <a
                href="https://dexscreener.com"
                target="_blank"
                rel="noopener noreferrer"
                className="flex h-[60px] items-center gap-2 bg-black text-white px-8 py-4  rounded-full hover:bg-gray-300 transition"
              >
                DexScreener <img className="h-7" src={dex} />
              </a>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="w-full bg-white text-gray-600 text-center p-4 mt-8">
          <p className="text-md">© {currentYear} Diamond Emoji on TON</p>
        </footer>
      </motion.div>
    </div>
  );
}

export default App;
