import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import tweet1 from "./assets/tweet1.png";
import tweet2 from "./assets/tweet2.png";
import tweet3 from "./assets/tweet3.png";
import tweet4 from "./assets/tweet4.png";

export default function Swipe() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={10}
      slidesPerView={1.2}
      scrollbar={{ hide: true }}
    >
      <SwiperSlide>
        <img
          className="ms-6 w-full object-cover rounded-2xl h-full"
          src={tweet4}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="ms-6 w-full h-full object-cover rounded-2xl"
          src={tweet1}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="ms-6 w-full h-full object-cover rounded-2xl"
          src={tweet2}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="ms-6 w-[90%] h-full object-cover rounded-2xl me-2"
          src={tweet3}
        />
      </SwiperSlide>
    </Swiper>
  );
}
